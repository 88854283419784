.head {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  line-height: 30px;
}

.head-light {
  color: hsl(210, 64%, 9%);
}

// For dark theme
.head-dark {
  color: #282c35;
}

.faq {
  width: 80%;
  margin: 0em auto 1em auto;
  display: flex;
  flex-direction: column;
  height: 33rem;
  align-items: center;
}

.faq-container {
  padding-bottom: 10px;
}

.faq-image {
  width: 95%;
  margin-left: auto;
}

.faq-block {
  margin-left: 2%;
  width: 60vw;
  height: auto;
  max-height: 27.9rem;
  border-radius: 8px;
  padding: 15px 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  scrollbar-width: none; //Hiding Scrollbar for Firefox
  -ms-overflow-style: none;
}

// Hiding Scrollbar
.faq-block::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.faq-block-light {
  background-color: hsl(210, 64%, 9%);
}

// For dark theme
.faq-block-dark {
  background-color: #282c35;
}

.faq-question {
  font-weight: 600;
  font-size: 1.1rem;
}

@media (max-width: 600px) {
  .head {
    font-size: 21px;
    flex-direction: column-reverse;
    line-height: 22px;
  }

  .faq {
    flex-direction: column;
    width: 95%;
    height: auto;
  }

  .faq-image {
    width: 90%;
  }

  .faq-block {
    width: 90%;
    margin: 1em 0 2em;
  }

  .faq-question {
    font-size: 0.85em;
  }

  .faq {
    height: 40rem;
  }
}

// For dark theme
.dark {
  background-color: #171717;
  color: whitesmoke;
}

// For dark theme
.accord-dark {
  background: linear-gradient(45deg, #4e4376 0%, #2b5876 90%);
  margin: 12px 0;
  border-radius: 10;

  &:hover {
    background: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
    transition: all 0.3s ease-in-out;
  }
}
