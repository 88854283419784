.head {
  text-align: center;
}

.manage-teams {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1.5em;
  margin: 2em;
}

.content {
  overflow: hidden;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
}

.crd {
  min-width: 100px;
  min-height: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #016795;
  box-shadow: rgba(141, 113, 113, 0.24) 0px 3px 8px;
  cursor: pointer;
  color: white;
  border-radius: 20px;
}

.crd:hover {
  color: white;
  background: #1b2431;
}

.crd > .head1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin: 0.5em;
}

.crd > .head1 > h4 {
  margin: 0 1em;
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .manage-teams {
    grid-template-columns: auto;
    margin: 1em 0;
  }

  .conts {
    width: 90%;
  }

  .content {
    overflow: hidden;
    padding: 1rem 1.2rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 1050px) {
  .content {
    overflow: hidden;
    padding: 1.5rem 3.5rem;
  }
}
