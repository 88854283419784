container {
  width: 90%;
  display : flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.welcome-section {
  position: relative;
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
  cursor: pointer;
  padding: 1rem;
  height: auto;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10em;
  border-radius: 20px;
  flex-direction: column;
}

.img-admin {
  width: 50%;
  margin: 1em;
}

.h1 {
  font-size: 20px !important;
  color: white !important;
  font-weight: bold;
  text-align: center;
}

.dashing {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto 3em auto;
}

.left {
  width: 25%;
  max-width: 290px;
  display: flex;
  flex-direction: column;
  min-height: 25em;
  margin-top: 2em;
  background-color: white;
  padding: 1em;
  border-radius: 12px;
  box-shadow: 0px 8px 10px 3px gray;
  transition: all 0.3s;
}

.right {
  width: 75%;
  margin: 2em 0em 0em 2em;
  background-color: white;
  padding: 1em;
  border-radius: 12px;
  box-shadow: 0px 8px 10px 3px gray;
  transition: all 0.3s;
}

.span {
  font-size: 17px;
  font-weight: bold;
  margin-left: 1em;
}

.features-icons {
  cursor: pointer;
  padding: 0.75em;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

.features-icons1 {
  padding: 0.75em;
  cursor: pointer;
  margin-top: 0.5em;
  border-radius: 20px;
  background: transparent;
  color: black;
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
}

.left > ul {
  padding-left: 0;
}

@media (max-width: 600px) {
  .dashing {
    flex-direction: column;
    width: 90%;
  }

  .left {
    width: 100%;
    max-width: unset;
  }

  .right {
    width: 100%;
    margin: 1em auto;
  }

  .welcome-section {
    flex-direction: row;
    justify-content: flex-start;
    min-height: 0;
    margin: 0;
  }

  .welcome-section > img {
    max-width: 40px;
    margin: 0;
  }

  .welcome-section > h1 {
    margin-top: 0;
    margin-left: 10px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 3.2rem;
    padding: 22px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }

  .menu {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;
    transition: max-height 0.5s;
  }

  .menu.active {
    max-height: 100vh;
  }

  .left {
    min-height: 0;
  }

  .left > ul > li:first-child {
    margin-top: 25px;
  }
}
