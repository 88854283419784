.popup {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  left: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.input-dark {
  color: whitesmoke !important;
}

.div {
  width: 40%;
  border-radius: 8px;
  background: white;
  padding: 1em;
  @media (max-width: 600px) {
    width: 95%;
  }
  h1 {
    width: 100%;
    font-size: 17px;
    margin: 0;
    text-align: center;
  }
}

// for dark theme
.div-dark {
  width: 40%;
  border-radius: 8px;
  background: #282c35;
  color: white !important;
  padding: 1em;
  @media (max-width: 600px) {
    width: 95%;
  }
  h1 {
    width: 100%;
    font-size: 17px;
    margin: 0;
    text-align: center;
  }
}

.form {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  div {
    margin: 0.5em 0;
  }
  h5 {
    margin: 0;
    width: 20%;
  }
  .input {
    width: 75% !important;
  }
}

.heading {
  color: #69a9dd !important;
  font-size: 25px !important;
}

.btns1 {
  background: linear-gradient(to right, #121f3a 0%, #2a4886 50%, #162f66 100%);
  background-size: 200% auto;
  outline: none;
  background-position: left center;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.23);
  -moz-box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.23);
  box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.23);
  border-radius: 50px;
  color: #fff;
  width: 15%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
}
