.admin_card {
  width: 70%;
  background-color: #e7e7e7;
  margin: auto;
  border-radius: 10px;
  box-shadow: 5px 5px 15px #888888, -5px -5px 15px #ffffff;
  padding-bottom: 2rem;
}

.cont {
  height: 100%;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
}

.admin_section {
  height: 80%;
  display: flex;
  align-items: center;
}

.h1 {
  text-transform: capitalize;
  color: var(--secondary-color);
  text-align: center !important;
  padding: 15px 0;
  margin-bottom: 0px;
}

.admin_input {
  position: relative;
  margin-bottom: 30px !important;
}

.admin_input input {
  width: 100%;
  height: 50px;
  border: 1px solid #bbbaba;
  border-radius: 5px;
  padding: 0 25px;
  color: #777777;
  background-color: #f1f1f1;
  box-shadow: inset 2px 2px 5px #888888, inset -2px -2px 5px #ffffff;
}

.admin_input input:focus {
  outline: none;
  border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, rgba(255, 0, 90, 1), rgba(10, 24, 61, 1));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #ffffff;
}

.admin_input i {
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 16px;
  color: rgb(134, 131, 131);
}

.inside_admin {
  width: 85%;
  margin: 1em auto;
}

.submit-btn {
  display: flex;
  justify-content: center;
  width: 40%;
  margin: 0 auto;
}

.submit-btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  padding: 1em 1.75em;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 600;
  color: #ffffff;
  background: linear-gradient(90deg, #121f3a 0%, #162f66 100%);
  border: none;
  border-radius: 1000px;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
}

.button:hover,
.button:focus {
  color: #ffffff;
}

@media screen and (max-width: 750px) {
  .button {
    min-width: 200px;
    font-size: 14px;
  }
  .admin_card {
    width: 90%;
  }
}

@media only screen and (max-width: 640px) {
  .button {
    min-width: 140px;
    font-size: 12px;
  }
  .admin_card {
    width: 90%;
  }
}
.validation {
  color: red;
  margin-top: 0;
}
