.scroll-top {
  position: fixed;
  right: 20px;
  bottom: 50px;
  height: 50px;
  width: 50px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeInOut 0.3s;
  transition: all 0.5s;
  color: var(--bs-blue);
  border: 3px solid var(--bs-blue);
  border-radius: 0.4em;
}

.scroll-top-dark {
  position: fixed;
  right: 20px;
  bottom: 50px;
  height: 50px;
  width: 50px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeInOut 0.3s;
  transition: all 0.5s;
  color: #7af7cd;
  border: 3px solid #7af7cd;
  border-radius: 0.4em;
}

.scroll-top:hover, .scroll-top-dark:hover {
  border-radius: 5em;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
