#main-dark {
  background-color: #171717;
}

.pp-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15% 3rem 15%;
  color: white;
  text-align: justify;
}

.pp-logo {
  width: 100%;
  height: auto;
}

.pp-statement-container {
  display: flex;
  flex-direction: column;
}

.pp-statement {
  padding-top: 20px;
}

.pp-content {
  color: black;
  font-size: 1.2rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.pp-content-dark {
  color: whitesmoke;
  font-size: 1.2rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

ul {
  list-style-type: none;
}

li {
  margin-top: 0.5rem;
}

.pp-subheading {
  font-size: 1.7rem;
  font-weight: 900;
  color: var(--secondary-color);
}

.pp-subheading-dark {
  font-size: 1.7rem;
  font-weight: 900;
  color: #69a9dd;
}

.by-line-dark {
  color: whitesmoke;
}

@media (orientation: portrait) {
  .pp-section {
    flex-direction: column;
    margin: 0;
  }

  .pp-logo {
    width: 85vw;
  }

  .pp-statement-container {
    margin: 2rem 4vw;
  }

  .pp-content {
    font-size: 1rem;
  }

  .pp-subheading {
    font-size: 1.3rem;
  }
}
