.slider-div {
  padding: 2em 0;
}

.slide {
  background-color: #121f3a;
  width: 100%;
  margin: 1em auto;
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}

// for dark theme
.slide-dark {
  box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1),
    0px -8px 16px 0px rgba(72, 127, 255, 0.1);
}

.carousel-head-light {
  color: #121f3a !important;
  font-size: 35px !important;
}

// for dark theme
.carousel-head-dark {
  color: #69a9dd !important;
  font-size: 35px !important;
}

.slide-card {
  position: relative;
  min-height: 31em;
  min-width: 20em;
  border-radius: 15px;
  margin: 30px;
  overflow: hidden;
  margin-bottom: 70px;
  backdrop-filter: blur(5px);
}

.slide-card-light {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  color: white;
}

// for dark theme
.slide-card-dark {
  box-shadow: 20px 20px 50px rgba(72, 127, 255, 0.1);
  background: #282c35;
  color: whitesmoke !important;
  border: 2px solid #016795;
}

.card-head {
  font-size: 24px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.card-text {
  line-height: 1.4;
  padding: 1em;
  padding-top: 2em;
  text-align: justify;
  text-justify: distribute-all-lines;
  font-size: 14px;
  word-spacing: 1px;
}

.slide-card-light:hover {
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
  color: white;
  cursor: pointer;
}

// for dark theme
.slide-card-dark:hover {
  background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
  color: whitesmoke;
  cursor: pointer;
}

.dash {
  width: 250px;
  height: 5px;
  border-radius: 20px;
  margin-bottom: 10px;
  background: rgb(255, 0, 90);
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

.dash-light {
  background: rgb(255, 0, 90);
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

//  for dark theme
.dash-dark {
  background: #2b5876;
  background: linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
}
