.forgot-password-recovery-section {
  display: flex;
  background-color: #fff;
}

.forgot-password-recovery-image {
  flex: 1;
  background-color: var(--secondary-color);
}

.child1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #fff;
}

.forgot-password-recovery-image img {
  max-width: 90%;
  margin-left: 5%;
}

.forgot-password-recovery-form {
  flex: 1;
}

.child2 {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.forgot-password-recovery-card {
  width: 75%;
  height: auto;
  background-color: #e7e7e7;
  margin: 20% auto;
  border-radius: 10px;
  box-shadow: 5px 5px 15px #888888, -5px -5px 15px #ffffff;
}

.card-heading {
  padding: 30px 0;
  text-align: center;
  color: var(--secondary-color);
  text-transform: capitalize;
}

.inside-card {
  width: 85%;
  margin: 0 auto;
  padding-bottom: 10px;
}

.forgot-password-recovery-input {
  position: relative;
}

.forgot-password-recovery-input input {
  width: 100%;
  height: 50px;
  border: 1px solid #bbbaba;
  border-radius: 10px;
  padding: 0 25px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: #777777;
  margin-bottom: 30px !important;
  background-color: #f1f1f1;
  box-shadow: inset 2px 2px 5px #888888, inset -2px -2px 5px #ffffff;
}

.input-forgot-password-recovery:focus {
  outline: none;
  border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, rgba(255, 0, 90, 1), rgba(10, 24, 61, 1));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #ffffff;
}

.forgot-password-recovery-input input:focus {
  border-color: #1863ff;
  outline: none;
  border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, rgba(255, 0, 90, 1), rgba(10, 24, 61, 1));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #ffffff;
}

.forgot-password-recovery-input input::placeholder {
  opacity: 1;
  color: #777777;
}

.forgot-password-recovery-input input::-webkit-input-placeholder {
  opacity: 1;
  color: #777777;
}

.forgot-password-recovery-input input:focus {
  background-color: #ffffff;
}

.input-forgot-password-recovery {
  outline: none;
}

.forgot-password-recovery-input i {
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 16px;
  color: #777777;
}

.forgot-password-recovery-input:nth-child(2) i:hover {
  cursor: pointer;
  color: rgb(17, 133, 17);
}

.submit-btn {
  display: flex;
  justify-content: center;
  width: 40%;
  margin: 0 auto;
  margin-bottom: 30px !important;
}

.submit-btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button {
  min-width: 100%;
  margin: 0px;
}

.no-border:focus {
  outline: none !important;
}

.no-border {
  outline: none !important;
}
@media screen and (max-width: 750px) {
  .forgot-password-recovery-section {
    flex-direction: column;
    width: 100%;
  }

  .forgot-password-recovery-card {
    width: 85%;
    margin: 10% auto;
  }

  .child1 {
    padding: 0 0 10px;
  }

  .submit-btn {
    width: 60% !important;
    margin: 0 auto;
  }

  .submit-btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-small;
    color: black;
  }
}
