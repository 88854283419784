.faq {
  display: flex;
  flex-direction: column;
  padding: 5px;
  height: 100%;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.card-item {
  background-color: #016795;
  font-size: 1.5rem;
  border-radius: 1em;
  box-shadow: 1em 1em 1em #363535;
  width: 35%;
  margin: 1em;
  transition: background 0.3s ease-out;
}

.clickable-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5em;
  color: var(--bs-light);
  height: 380px;
}

.card-title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  line-height: 1.9rem;
  font-weight: bold;
}

.card-title:hover {
  cursor: pointer;
}

.card-content {
  font-weight: normal;
  font-size: 1.2rem;
  width: 100%;
}

.card-item:hover {
  background-color: #1b2431;
}

.editt {
  margin-left: 8px;
  color: red;
  margin-bottom: 20px;
}

.add {
  margin-left: 5px;
  margin-bottom: 6px;
}

.main-btn {
  font-family: "Futura LT Book";
  display: inline-block;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 40px;
  font-size: 16px;
  line-height: 48px;
  border-radius: 50px;
  color: black;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #fff;
  border: 0;
}

.main-btn:hover {
  background-color: #fff;
  color: #1863ff;
  -webkit-box-shadow: 0px 5px 29px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 29px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 29px 0px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 768px) {
  .cards {
    flex-direction: column;
  }

  .card-item {
    width: 90%;
    margin: 1em auto;
  }

  .main-btn {
    text-align: center;
  }
}
