.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-info {
  display: flex;
  text-align: center;
  justify-content: center;
}

.appbar-wrap {
  width: 100%;
  z-index: 2;
}

.appbar {
  margin: auto;
  min-height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.filters {
  display: flex;
  margin: 15px 10px 15px auto;
}

.dash {
  width: 250px;
  height: 5px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.dash-light {
  background: rgb(255, 0, 90);
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

// for dark theme
.dash-dark {
  background: #2b5876;
  background: linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
}

#hero {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 100vw;
}

#heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 0 1rem 0;
  text-align: center;
}

#all-cards {
  display: flex;
  flex-wrap: wrap;
  width: 95vw;
  margin: 0.5em;
  justify-content: center;
}
#all-cards h1 {
  margin: 10%;
}
.search-icon {
  padding-left: 16px;
  padding-right: 16px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-input {
  padding: 8px 8px 8px 0;
  border-radius: 12px;
  height: 3em;
  padding-left: calc(1em + 32px);
  width: 100%;
}

// for dark theme
.input-input-dark {
  color: whitesmoke !important;
}

.search {
  border-radius: 15px;
  margin-right: 16px;
  margin-left: 25px;
  position: relative;
  width: 228px;
  // border: 3px solid #1B2431;
}

.search-light {
  background-color: rgba(124, 117, 117, 0.2);
  box-shadow: 0.5em 0.5em 0.5em rgb(54, 53, 53);
}

// for dark theme
.search-dark {
  border: 1px solid #414a4c;
  background-color: #333333;
  box-shadow: 0.5em 0.5em 0.5em rgba(72, 127, 255, 0.4);
}

// for dark theme
.search-dark:focus-within,
.search-dark:hover {
  background-image: linear-gradient(to right, #2b5876, #4e4376);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.search-light:hover,
.search-light:focus-within {
  background-color: white !important;
}

.carousel-head {
  color: #121f3a !important;
  font-size: 35px !important;
}

// for dark theme
.carousel-head-dark {
  color: #69a9dd !important;
  font-size: 35px !important;
}

@media (min-width: 380px) {
  .appbar {
    width: 80vw;
  }
}

@media (max-width: 780px) {
  .appbar {
    justify-content: center;
  }

  .filters {
    display: flex;
    margin: 40px auto;
  }

  .filters > * {
    background-color: #121f3a !important;
  }
}

@media screen and (orientation: portrait) {
  #hero {
    flex-direction: column;
  }
}

@media screen and (min-width: 600px) {
  .search {
    width: auto;
  }
}

@media screen and (min-width: 960px) {
  .input-input {
    width: 40ch;
  }
}

.filter-btn {
  background-color: #121f3a !important;
}

// for dark theme
.dark {
  background: #171717;
  color: whitesmoke;
}

h1 {
  white-space: nowrap;
}

#all-cards h1 {
  margin-bottom: 20rem;
}
