.navbar-div {
  background: #081624;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
}

.navbar-div-dark {
  background: #081624;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 10;
  box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1),
    0px -8px 16px 0px rgba(72, 127, 255, 0.1);
}

.mobile-toggle {
  display: none;
}

.nav-toggle {
  display: block;
  cursor: pointer;
}

.navbar-logo {
  justify-self: start;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
}

.navbar-logo-dark {
  justify-self: start;
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
}

.navbar-logo img:hover {
  filter: hue-rotate(130deg) contrast(180%);
}

.navbar-logo-dark img:hover {
  filter: brightness(1000%);
}

.nav-menu {
  display: flex;
  text-align: center;
  width: 70vw;
  padding-left: 1rem;
  padding-top: 16px;
}

.nav-menu-dark {
  display: flex;
  text-align: center;
  width: 70vw;
  padding-left: 1rem;
  padding-top: 16px;
}

.nav-item {
  display: flex;
  margin: 5px;
  align-items: center;
  height: 60px;
}

.nav-item-dark {
  display: flex;
  margin: 5px;
  align-items: center;
  height: 60px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links-dark {
  color: #69a9dd;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-admin-button {
  padding: 8px 11px;
  border-radius: 4px;
  outline: none;
  border: 2px solid #fc0254;
  font-size: 16.5px;
  color: #fff;
  cursor: pointer;
  background-color: #fc0254;
  transition: all 0.3s ease-out;
}

.nav-admin-button-dark {
  padding: 8px 11px;
  border-radius: 4px;
  outline: none;
  border: 2px solid #69a9dd;
  font-size: 16.5px;
  color: #171717;
  cursor: pointer;
  background-color: #69a9dd;
  transition: all 0.3s ease-out;
}

.nav-admin-button:hover {
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid #fc0254;
}

.nav-admin-button-dark:hover {
  background-color: hsl(210, 64%, 9%);
  color: whitesmoke;
  border-radius: 4px;
  border: 2px solid #69a9dd;
}

.button-div {
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  border: 2px solid #fc0254;
}

.button-div-dark {
  background-color: transparent;
  color: whitesmoke;
  border-radius: 4px;
  border: 2px solid #69a9dd;
}

a.nav-links:hover {
  background-color: #125181;
  border-radius: 8px;
  transition: all 0.2s ease-out;
  color: white;
}

a.nav-links-dark:hover {
  background-color: #69a9dd;
  border-radius: 8px;
  transition: all 0.1s ease-out;
  color: hsl(210, 64%, 9%);
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.nav-links-mobile-dark {
  display: none;
}

.menu-icon {
  display: none;
}

.menu-icon-dark {
  display: none;
}

.active-link {
  border-bottom: 2px;
  border-radius: 8px;
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

.active-link-dark {
  border-bottom: 2px;
  border-radius: 8px;
  background: linear-gradient(45deg, #4e4376 0%, #2b5876 90%);
  color: whitesmoke;
}

@media screen and (min-width: 860px) and (max-width: 1050px) {
  .nav-item {
    font-size: 0.8rem;
  }

  .nav-item-dark {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 860px) {
  .fa-caret-down {
    display: none;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-admin-button {
    display: none;
  }

  .nav-admin-button-dark {
    display: none;
  }

  .mobile-toggle {
    text-align: center;
    padding: 1.5rem;
    margin: 0 auto;
    display: block;
  }

  .nav-toggle {
    display: none;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 1rem;
  }

  .nav-menu-dark {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 1rem;
  }

  .nav-menu.active {
    background-color: #242222;
    background-color: #0a183d;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 150;
  }

  .nav-menu-dark.active {
    background-color: #171717;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 150;
  }

  .nav-item {
    height: auto;
  }

  .nav-item-dark {
    height: auto;
  }

  .nav-links {
    text-align: center;
    width: 55%;
    margin-left: 22%;
  }

  .nav-links-dark {
    text-align: center;
    width: 55%;
    margin-left: 22%;
  }

  .nav-links:hover {
    background-color: #125181;
    border-radius: 0;
  }

  .nav-links-dark:hover {
    background-color: #69a9dd;
    border-radius: 0;
  }

  .navbar-logo {
    margin-left: 1rem;
  }

  .navbar-logo-dark {
    margin-left: 1rem;
  }

  .navbar-div {
    justify-content: flex-start;
  }

  .navbar-div-dark {
    justify-content: flex-start;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }

  .menu-icon-dark {
    display: block;
    position: absolute;
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #69a9dd;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 8px;
    width: 55%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile-dark {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 8px;
    width: 55%;
    background: #69a9dd;
    text-decoration: none;
    color: whitesmoke;
    font-size: 1.5rem;
  }

  .nav-links-mobile > span {
    font-family: sans-serif;
  }

  .nav-links-mobile-dark > span {
    font-family: sans-serif;
  }

  .nav-links-mobile {
    padding: 8px 20px;
    outline: none;
    font-size: 18px;
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    border: 2px solid #fc0254;
  }

  .nav-links-mobile-dark {
    padding: 8px 20px;
    outline: none;
    font-size: 18px;
    cursor: pointer;
    background-color: transparent;
    color: whitesmoke;
    border-radius: 4px;
    border: 2px solid #69a9dd;
  }

  .nav-links-mobile:hover {
    color: #fff;
    transition: 250ms;
  }

  .nav-links-mobile-dark:hover {
    color: whitesmoke;
    transition: 250ms;
  }

  .nav-item.faq {
    display: none;
  }

  .nav-item-dark.faq {
    display: none;
  }

  .navbar button {
    display: none;
  }

  .navbar-dark button {
    display: none;
  }
}
