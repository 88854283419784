.modals {
  width: 75%;
  border-radius: 10px;
}

.modals-light {
  color: black;
  background-color: white !important;
}

// for dark theme
.modals-dark {
  color: whitesmoke;
  background-color: #171717 !important;
}

@media (min-width: 992px) and (max-width: 1400px) {
  .modals {
    position: fixed;
    height: 73%;
  }

  .link {
  }

  .main-cont {
    flex-direction: column;
    overflow-y: scroll;
    height: auto;
    max-height: 100%;
  }

  .image {
    width: 95%;
  }

  .cont {
    width: 95%;
    font-size: 15px;
  }
}

.modal {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.link {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  padding: 1em 0;
}

.link-light {
  color: white;
  background-color: #016795;
}

// for dark theme
.link-dark {
  color: whitesmoke;
  background-color: #121f3a;
}

.link-light:hover {
  cursor: pointer;
  color: #121f3a;
}

// for dark theme
.link-dark:hover {
  cursor: pointer;
  color: #016795;
}

.mod_head {
  background-color: #016795;
  font-size: 25px;
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 1em 0;
  align-items: center;
}

// for dark theme
.mod_head-dark {
  background-color: #121f3a;
  color: whitesmoke;
}

.mod_head-light {
  background-color: #016795;
  color: white;
}

.close-btn {
  float: right;
  padding-right: 25px;
}

.close-btn-light:hover {
  color: #121f3a;
  cursor: pointer;
}

// for dark theme
.close-btn-dark:hover {
  color: #016795;
  cursor: pointer;
}

.main-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  overflow-y: scroll;
  height: auto;
  max-height: 70%;
}

.main-cont::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

.main-cont::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.main-cont::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  background-image: linear-gradient(#69a9dd, #121f3a);
  border-left: 0px;
  border-right: 0px;
  &:hover {
    background-image: linear-gradient(#69a9dd, #121f3a);
  }
}

.image {
  width: 48%;
  border-radius: 20px;
}

.cont {
  width: 48%;
  font-weight: 500;
  font-size: 16px;
  line-height: 2;
}

@media (max-width: 991px) {
  .modals {
    width: 95%;
    height: 68%;
  }

  .link {
    height: auto;
  }

  .main-cont {
    flex-direction: column;
    padding: 0.75em 0;
    overflow-y: scroll;
    height: auto;
    max-height: 70%;
  }

  .image {
    width: 95%;
  }

  .cont {
    margin: 1em 0;
    width: 95%;
    font-size: 14px;
    line-height: 1.2;
  }
}
