.container {
  height: 100%;
  display: flex;
}

.h1 {
  text-align: center;
}

.content {
  overflow: hidden;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;
}

.contain {
  width: 90%;
  margin: 0em auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 70%;
}

.card {
  display: flex;
  width: 40%;
  max-width: 305px;
  height: auto;
  min-height: 12em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  border-radius: 1em;
  margin: 1em;
  transition: all 0.5s ease-in;
}

.card > .card-content > .head1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin-bottom: -15px;
}

.card > .card-content > .head1 > h4 {
  margin-left: 0.7em;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.card > .card-content > .head1 > span {
  margin-top: 2px;
}

.card-content {
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.para {
  min-width: 70%;
  padding: 0.5em;
  color: white;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  border-radius: 20px;
}

.card-item-light {
  background-color: #016795;
  box-shadow: 1em 1em 1em rgb(54, 53, 53);
}

// for dark theme
.card-item-dark {
  background-color: #171717;
  border: 2px solid #016795;
  box-shadow: 1em 1em 1em rgba(72, 127, 255, 0.4);
  color: whitesmoke !important;
}

.card-item-light:hover {
  background-color: #1b2431;
}

// for dark theme
.card-item-dark:hover {
  background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
}

@media (max-width: 600px) {
  .contain {
    width: 90%;
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin: 1em auto;
  }
}
