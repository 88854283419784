.add-broadcasts-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.editor {
  padding: 30px;
  overflow-x: hidden;
}

.card {
  width: 75%;
  height: auto;
  background-color: #e7e7e7;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 15px #888888, -5px -5px 15px #ffffff;
}

.tags-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: inset 2px 2px 5px #888888, inset -2px -2px 5px #ffffff;

  & > input {
    flex: 1;
    border: none;
    font-size: 14px;
    margin: 0;
    outline: none;
    background: transparent;
  }
}

.form-control {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.form-control-input {
  position: relative;
}

.form-control-input,
.form-control-input {
  width: 100%;
  height: 50px;
  border: 1px solid #bbbaba;
  border-radius: 10px;
  padding: 0 25px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  color: #777777;
  margin-bottom: 7px;
  background-color: #f1f1f1;
  box-shadow: inset 2px 2px 5px #888888, inset -2px -2px 5px #ffffff;
}

.form-control-input::placeholder,
.form-control-input::placeholder {
  opacity: 1;
  color: #777777;
}

.form-control-input::-moz-placeholder,
.form-control-input::-moz-placeholder {
  opacity: 1;
  color: #777777;
}

.form-control-input::-webkit-input-placeholder,
.form-control-input::-webkit-input-placeholder {
  opacity: 1;
  color: #777777;
}

.form-control-input:focus {
  border-color: #1863ff;
  outline: none;
  border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, rgba(255, 0, 90, 1), rgba(10, 24, 61, 1));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #ffffff;
}

.form-control i {
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 16px;
  color: #777777;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.tag {
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 10px;
  background-color: #f5f5f5;
  color: #333;
  font-size: 14px;
  cursor: pointer;

  & .tag-remove {
    margin-left: 5px;
    cursor: pointer;
  }

  & .tag-label {
    font-weight: 900;
  }

  &:nth-last-child() {
    margin-right: 0;
  }
}

.heading {
  text-align: center;
}

.motive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.edit {
  padding: 20px;
  border: 1px solid #bbbaba;
  border-radius: 10px;
  background-color: #f1f1f1;
  box-shadow: inset 2px 2px 5px #888888, inset -2px -2px 5px #ffffff;
}

.dash {
  width: 250px;
  height: 5px;
  border-radius: 20px;
  margin-bottom: 10px;
  background: rgb(255, 0, 90);
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

.headingg {
  padding: 5px;
  font-weight: bold;
  font-size: large;
}

@media screen and (max-width: 600px) {
  input {
    margin-left: 0;
  }
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0 auto;
  width: 30%;
}

.submit-btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .submit-btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-small;
    color: black;
  }

  .submit-btn {
    width: 60% !important;
    margin: 0 auto;
  }
}

.data-loader {
  width: 100%;
  display: flex;
  height: 10px;
  justify-content: center;
  align-items: center;
}