@font-face {
  font-family: "Futura LT Book";
  src: url("./fonts/FuturaLT-Book.ttf");
  src: local("Futura LT Book"),
    url("./fonts/FuturaLT-Book.ttf") format("truetype");
  src: url("./fonts/FuturaLT-Book.ttf") format("truetype");
}

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}
