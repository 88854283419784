.faq-section {
  background: #fff;
  padding: 0 20px;
}

.faq-parent {
  display: flex;
  padding: 30px 0;
}

.faq-child {
  flex: 1;
}

.child1 {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.validation {
  color: red;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.faq-card {
  width: 75%;
  height: auto;
  background-color: #e7e7e7;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 15px #888888, -5px -5px 15px #ffffff;
}

.faq-header-text {
  padding: 15px 0;
  margin-bottom: 0px;
  text-transform: capitalize;
  color: var(--secondary-color);
  text-align: center !important;
}

.inside-faq {
  width: 85%;
  margin: 0 auto;
}

.faq-input {
  position: relative;
}

.faq-input input,
.faq-input textarea {
  width: 100%;
  height: 50px;
  border: 1px solid #bbbaba;
  border-radius: 10px;
  padding: 0 25px;
  margin-left: auto;
  margin-right: auto;
  color: #777777;
  margin-bottom: 0px;
  background-color: #f1f1f1;
  box-shadow: inset 2px 2px 5px #888888, inset -2px -2px 5px #ffffff;
}

.faq-input input::placeholder,
.faq-input textarea::placeholder {
  opacity: 1;
  color: #777777;
}

.faq-input input::-moz-placeholder,
.faq-input textarea::-moz-placeholder {
  opacity: 1;
  color: #777777;
}

.faq-input input::-webkit-input-placeholder,
.contact-input textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #777777;
}

.faq-input input:focus,
.faq-input textarea:focus {
  border-color: #1863ff;
  outline: none;
  border: double 2px transparent;
  border-radius: 10px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, rgba(255, 0, 90, 1), rgba(10, 24, 61, 1));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #ffffff;
}

.faq-input textarea {
  padding-top: 15px;
  height: 180px;
  resize: none;
}

.faq-input i {
  position: absolute;
  right: 25px;
  top: 15px;
  font-size: 16px;
  color: #777777;
}

.submit-btn {
  display: flex;
  justify-content: center;
  width: 40%;
  margin: 0 auto;
}

.submit-btn-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedClass {
  margin-top: 10px;
}

.tagClass {
  margin-right: 10px;
  color: white;
  background-color: #0275d8;
  border-radius: 33px;
  padding: 5px;
}

.removeClass {
  border-radius: 33%;
  margin-left: 1%;
  background-color: #0275d8;
  border-style: none;
  color: white;
  padding-right: 5px;
}

@media screen and (max-width: 750px) {
  .faq-parent {
    display: block;
    width: 100%;
  }

  .faq-card {
    width: 95%;
    margin: 10% auto;
  }

  .faq-input input,
  .faqBtn {
    height: 40px;
  }

  .faq-btn {
    font-size: 20px;
  }

  .submit-btn {
    width: 60% !important;
    margin: 0 auto;
  }

  .submit-btn-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: x-small;
    color: black;
  }
}
