.submit-btn {
  position: relative;
  display: flex;
  justify-content: center;
}

.myb {
  padding: 12px 22px;
  border-radius: 4px;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  margin-bottom: 20px;
}

//For dark theme
.myb-dark {
  background: #69a9dd;
  border: 2px solid #69a9dd;
  color: #171717;
}

.myb-light {
  background: rgba(10, 24, 61, 1);
  border: 2px solid rgba(10, 24, 61, 1);
  color: #fff;
}

.myb-light:hover {
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
  color: #fff;
  border-radius: 4px;
  border: 2px solid
    linear-gradient(45deg, rgba(255, 0, 90, 1) 0%, rgba(10, 24, 61, 1) 90%);
}

// for dark theme
.myb-dark:hover {
  background: linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  color: whitesmoke;
  border-radius: 4px;
  border: 2px solid linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
}

// for dark theme
.dark {
  background: #171717;
  color: whitesmoke;
}
