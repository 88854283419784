.motive-div {
  background-color: hsl(210, 64%, 9%);
  padding: 60px 0px;
}

.single-features {
  -webkit-box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1);
  -moz-box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  z-index: 5;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
}

// for dark theme
.single-features-dark {
  background-color: #171717;
  color: whitesmoke;
}

.single-features-light {
  background-color: white;
}

.single-features-light::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  left: 0;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: rgb(255, 0, 90);
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

.single-features-dark::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  left: 0;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: #2b5876;
  background: linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
}

.single-features .features-icon {
  position: relative;
  align-items: center;
  display: flex;
  padding-right: 18px;
}

// for dark theme
.single-features-dark .features-icon i {
  font-size: 40px;
  background: -webkit-linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  background: -o-linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  background: -moz-linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  background: linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-features-light .features-icon i {
  font-size: 40px;
  background: -webkit-linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
  background: -moz-linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-features-light:hover .features-icon i {
  background: white;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

// for dark theme
.single-features-dark:hover .features-icon i {
  background: #171717;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.single-features-light .features-icon span {
  font-size: 60px;
  line-height: 100px;
  opacity: 0.08;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-weight: 600;
  color: #1b273d;
}

// for dark theme
.single-features-dark .features-icon span {
  font-size: 60px;
  line-height: 100px;
  opacity: 0.08;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-weight: 600;
  color: #69a9dd;
}

.single-features .features-content {
  padding-top: 5px;
  position: relative;
  z-index: 9;
}

.single-features .features-content .features-title a {
  font-size: 24px;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-features-light .features-content .features-title a {
  color: #0e182c;
}

// for dark theme
.single-features-dark .features-content .features-title a {
  color: #69a9dd;
}

.single-features .features-content .text {
  margin-top: 12px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-features:hover::before {
  opacity: 1;
}

.single-features-light:hover .features-icon i,
.single-features-light:hover .features-icon span {
  color: #fff;
}

// for dark theme
.single-features-dark:hover .features-icon i,
.single-features-dark:hover .features-icon span {
  color: #171717;
}

.single-features:hover .features-content .features-title a {
  color: #fff;
  text-decoration: none;
}

.single-features:hover .features-content .text {
  color: #fff;
}

.single-features:hover .motive-content {
  color: #fff;
}

.motive {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.motive .dash {
  width: 250px;
  height: 5px;
  border-radius: 20px;
  margin-bottom: 10px;
  background: rgb(255, 0, 90);
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

.motive-light .dash-light {
  background: rgb(255, 0, 90);
  background: linear-gradient(
    45deg,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1) 90%
  );
}

// for dark theme
.motive-dark .dash-dark {
  background: #2b5876;
  background: linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
}

.fa-crosshairs-light {
  font-size: 3.4rem;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1)
  );
  background: -o-linear-gradient(
    right,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1)
  );
  background: -moz-linear-gradient(
    right,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1)
  );
  background: linear-gradient(
    to right,
    rgba(255, 0, 90, 1) 0%,
    rgba(10, 24, 61, 1)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

// for dark theme
.fa-crosshairs-dark {
  font-size: 3.4rem;
  background: -webkit-linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  background: -o-linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  background: -moz-linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  background: linear-gradient(45deg, #2b5876 0%, #4e4376 90%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.motive h1 {
  font-size: 3.2rem;
}

.motive-light h1 {
  color: white;
}

// for dark theme
.motive-dark h1 {
  color: #69a9dd;
}

@media screen and (max-width: 823px) {
  .motive {
    flex-direction: column;
    align-items: center;
    padding: 1.8rem 0;
    width: 100%;
  }

  .motive > div {
    padding-left: 0;
  }

  .motive h1 {
    font-size: 2.8rem;
  }
}

// for dark theme
.dark {
  color: whitesmoke;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1),
    0px -8px 16px 0px rgba(72, 127, 255, 0.1);
  -moz-box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1),
    0px -8px 16px 0px rgba(72, 127, 255, 0.1);
  box-shadow: 0px 8px 16px 0px rgba(72, 127, 255, 0.1),
    0px -8px 16px 0px rgba(72, 127, 255, 0.1);
}
