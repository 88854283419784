.fa-twitter-square {
  color: #1da1f2 !important;
}

.fa-twitter-square-light:hover {
  color: #1e5475 !important;
  cursor: pointer;
}

.fa-twitter-square-dark:hover {
  color: whitesmoke !important;
  cursor: pointer;
}

.sl {
  color: #ecb22e !important;
}

.fa-3x {
  font-size: 28px;
}

.in {
  color: #0e76a8 !important;
}

.in-light:hover {
  cursor: pointer;
  color: #133646 !important;
}

.in-dark:hover {
  cursor: pointer;
  color: whitesmoke !important;
}

.fa-github-square {
  color: #6e5494 !important;
}

.fa-github-square-light:hover {
  color: #402b5f !important;
  cursor: pointer;
}

.fa-github-square-dark:hover {
  color: whitesmoke !important;
  cursor: pointer;
}

.badge {
  color: #000;
  box-shadow: -1px 16px 40px 0px rgba(0, 0, 0, 0.19) !important;
}
