.container {
  top: 2px;
  padding-left: 4rem;
}

.label {
  position: relative;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px;
  border-radius: 50px;
  height: 26px;
  width: 50px;
  -webkit-transition: 0.4s;
  transition: 0.7s;
}

.checkbox {
  display: none;
}

.checkbox:checked + .label .slider {
  background-color: #69a9dd;
  transform: translateX(24.5px);
}

.checkbox:checked + .label {
  background-color: #282c35;
}

.moon {
  transform: scale(1.3);
  color: whitesmoke;
}

.sun {
  transform: scale(1.3);
  color: #999900;
}

.slider {
  position: absolute;
  cursor: pointer;
  left: 2px;
  background-color: #0a183d;
  -webkit-transition: 0.4s;
  transition: 0.7s;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
