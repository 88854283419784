.card-container {
    width: 100% ;
    margin-left: 10rem;
}

.data-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}